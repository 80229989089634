<template>
  <ValidationObserver ref="categoryForm">
    <EKDialog
      :title="title"
      :placeholder="placeholder"
      :btnText="btnText"
      ref="mainCategoryDialog"
      @ok="createCategory"
      @search="search"
      @delete="submitDeleteMainCategory(categoryDto.id)"
      :isEdit="!!(categoryDto.id != nullGuid)"
      @close="reset"
    >
      <template #body>
        <EKInputText
          v-model="categoryDto.name"
          :rules="[
            {
              type: 'required',
              message: 'اسم التصنيف الرئيسي مطلوب',
            },
          ]"
          label="اسم التصنيف الرئيسي"
          placeholder="ادخل اسم التصنيف الرئيسي"
          name="name"
        />

        <EKInputTextarea
          v-model="categoryDto.description"
          :rules="[
            {
              type: 'required',
              message: 'وصف التصنيف الرئيسي مطلوب',
            },
          ]"
          label="وصف التصنيف الرئيسي"
          placeholder="ادخل وصف التصنيف الرئيسي"
          name="description"
        />
        <EKInputImage
          ref="mainCategoryImage"
          label="صورة التصنيف الرئيسي"
          @input="categoryDto.imageFile = $event"
          :value="
            categoryDto.imagePath
              ? $store.getters['app/domainHost'] + '/' + categoryDto.imagePath
              : categoryDto.imageFile
          "
          required
          title="أرفق الملف هنا أو اضغط لتحميل"
        ></EKInputImage>
      </template>
    </EKDialog>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState, mapMutations } from "vuex";
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import { nullGuid } from "@Ekcore/util/global.js";

export default {
  components: {
    ValidationObserver,
    EKDialog,
    EKInputText,
    EKInputImage,
    EKInputTextarea,
  },
  data: () => ({
    nullGuid,
  }),
  computed: {
    ...mapState({
      categoryDto: (state) => state.categories.categoryDto,
      mainCategoriesList: (state) => state.categories.categoryDto,
    }),
  },
  props: {
    title: {
      type: String,
      default: () => "إضافة تصنيف جديد",
    },
    placeholder: {
      type: String,
      default: () => "ابحث عن تصنيف محدد",
    },
    btnText: {
      type: String,
      default: () => "تصنيف جديد",
    },
  },
  methods: {
    ...mapActions(["addCategory", "updateCategory", "deleteCategory"]),
    ...mapMutations(["Rest_Parent_Category_Id"]),
    search(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name"],
        query,
      });
    },
    open() {
      this.$refs.mainCategoryDialog.open();
    },
    createCategory() {
      this.$refs.categoryForm.validate().then((success) => {
        if (success && (this.categoryDto.imageFile || this.categoryDto.imagePath)) {
          let categoryFormData = new FormData();
          categoryFormData.append("id", this.categoryDto.id);
          categoryFormData.append("name", this.categoryDto.name);
          categoryFormData.append("description", this.categoryDto.description);
          categoryFormData.append("imageFile", this.categoryDto.imageFile);
          categoryFormData.append("imagePath", this.categoryDto.imagePath);
          if (this.categoryDto.id == nullGuid) {
              this.addCategory(categoryFormData);
          } else {
            categoryFormData.append("language", this.categoryDto.language);
            this.updateCategory(categoryFormData);
          }
          this.$refs.categoryForm.reset();
          this.$refs.mainCategoryImage.clear();
          this.$store.commit("Reset_Category_Dto");
          this.$refs.mainCategoryDialog.close();
        }
      });
    },
    reset() {
      this.$refs.categoryForm.reset();
      this.$refs.mainCategoryImage.clear();
      this.$store.commit("Reset_Category_Dto");
    },
    submitDeleteMainCategory(id) {
      this.deleteCategory(id);
      this.$refs.mainCategoryDialog.close();
    },
  },
  watch: {
    is(is) {
      if (!is) {
        this.$store.commit("Reset_Category_Dto");
      }
    },
  },
};
</script>
