var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"categoryForm"},[_c('EKDialog',{ref:"mainCategoryDialog",attrs:{"title":_vm.title,"placeholder":_vm.placeholder,"btnText":_vm.btnText,"isEdit":!!(_vm.categoryDto.id != _vm.nullGuid)},on:{"ok":_vm.createCategory,"search":_vm.search,"delete":function($event){return _vm.submitDeleteMainCategory(_vm.categoryDto.id)},"close":_vm.reset},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('EKInputText',{attrs:{"rules":[
          {
            type: 'required',
            message: 'اسم التصنيف الرئيسي مطلوب',
          } ],"label":"اسم التصنيف الرئيسي","placeholder":"ادخل اسم التصنيف الرئيسي","name":"name"},model:{value:(_vm.categoryDto.name),callback:function ($$v) {_vm.$set(_vm.categoryDto, "name", $$v)},expression:"categoryDto.name"}}),_c('EKInputTextarea',{attrs:{"rules":[
          {
            type: 'required',
            message: 'وصف التصنيف الرئيسي مطلوب',
          } ],"label":"وصف التصنيف الرئيسي","placeholder":"ادخل وصف التصنيف الرئيسي","name":"description"},model:{value:(_vm.categoryDto.description),callback:function ($$v) {_vm.$set(_vm.categoryDto, "description", $$v)},expression:"categoryDto.description"}}),_c('EKInputImage',{ref:"mainCategoryImage",attrs:{"label":"صورة التصنيف الرئيسي","value":_vm.categoryDto.imagePath
            ? _vm.$store.getters['app/domainHost'] + '/' + _vm.categoryDto.imagePath
            : _vm.categoryDto.imageFile,"required":"","title":"أرفق الملف هنا أو اضغط لتحميل"},on:{"input":function($event){_vm.categoryDto.imageFile = $event}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }